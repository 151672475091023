import React, { useState } from "react"
import { Link } from "gatsby"
import { Location } from '@reach/router'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import arrowLeft from '../../content/assets/arrow-left.svg'
import arrowRight from '../../content/assets/arrow-right.svg'
import Layout from "../components/Layout"


export default () => {

  const data = useStaticQuery(graphql`
    query CategoryQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___order], order: ASC } ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            category
            order
            herobgColor
            cover {
                publicURL
                childImageSharp{
                  sizes(maxWidth: 1240 ) {
                   ...GatsbyImageSharpSizes
                  }
                }
            }
          }
        }
      }
    }
  }
  `)

  const posts = data.allMarkdownRemark.edges
  const [categoryToUse, setCategoryToUse] = useState('');

  return (
    <Layout>
    <div className="w-100">


      <div className='bg-white w-100 cf animated faster ph4 fadeIn' >

      <div className="w-100 cf">
          <div className="w-100 mw9 center ">
               <div className="flex justify between pv3">
                  <Link className="w-100" to="/">
                      <div className="flex items-center">
                          <img className="square-1-5" src={arrowLeft} alt="arrow-right"/>
                          <div className="f6 fw3 gray pl3 tracked">back</div>
                      </div>
                  </Link>
               </div>
              </div>
            </div>



        <div className="w-100 pb6 cf mb6">
            <div className="w-100 mw9 center pt4 pb6 ">


                    <Location>
                      {({ location }) => { const categoryToUse = location.hash.replace("#", "").replace( /%20/g, " "); setCategoryToUse( categoryToUse )  }}
                    </Location>

                    <div className="w-100 f4 fw5 tracked bb b--black-10 pb3 ttu mb4">{categoryToUse}</div>
                   
                      {posts.filter(x => x.node.frontmatter.category === categoryToUse ).map(({ node }) => {
                        const title = node.frontmatter.title || node.fields.slug
                        return (
                          <div className="fl w-100 w-50-m w-third-l pr0 pr3-m pr4-l h-500" key={node.fields.slug} >
                            <Link className="link dim black cf" to={node.fields.slug}>
                                <div className="flex flex-column w-100">
                                    <div className={"w-100 " + node.frontmatter.herobgColor} >
                                      { !node.frontmatter.cover.childImageSharp  ? 
                                         <div className="flex-hv-center h-fluid" >
                                           <img className='w-90 w-80-ns' src={node.frontmatter.cover.publicURL} alt="..." />
                                         </div>
                                        :
                                         <Img className="mw-100 h-fluid " sizes={node.frontmatter.cover.childImageSharp.sizes} />
                                      } 
                                    </div>
                                    <div className="pv4">
                                       <div className="f8 theme-menu fw5 mb0 lh-solid tracked-mega mb2 ttu">{node.frontmatter.category}</div>
                                       <div className="f4 f5-ns black fw5 mt1 mb3 lh-1-6 tracked-sm lh-1-3">{title}</div>
                                       <div className="f5 fw3 dark-gray tracked-sm lh-copy">{node.excerpt.replace(/\..*$/i, '.')}</div>
                                       <img className="square-1 mt3" src={arrowRight} alt="arrow-right"/>
                                    </div>
                                </div>
                            </Link>
                          </div>
                        )
                        
                      })}
                       
                   
              </div>


              
           
          </div>
      </div>


      </div>
      </Layout>
  )
}
